<template>
  <div class="container-fluid px-0">
    <div class="my-3">
      <h3 class>
        <b-link href="https://api.morgansolar.xyz/docs" target="_blank">API Docs</b-link>
      </h3>
    </div>

    <b-form-checkbox
      v-model="checked"
      class="pb-1"
      switch
      @input="handleBaseUrlChange"
    >{{ checked ? 'Live URL' : 'Local URL' }}</b-form-checkbox>

    <b-form-group>
      <b-input-group>
        <b-input-group-prepend>
          <b-form-select
            v-model="method"
            v-on:change="handleMethodChange"
            :plain="true"
            :options="methods"
            class="method"
          ></b-form-select>
        </b-input-group-prepend>
        <b-form-input
          placeholder="URL"
          v-model="url"
          @keyup.enter.native="handleFetch"
          @keyup.native="handleUrlChange"
        ></b-form-input>
        <b-input-group-append>
          <b-button variant="primary" @click="handleFetch">Send</b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>

    <div>
      <h5>Query Parameters</h5>
      <b-row>
        <b-col md="12" lg="6">
          <div class="query mt-1" v-for="(query, index) in queries" :key="index">
            <b-form-input
              placeholder="Key"
              :value="query.key"
              @input="handleInputChange($event, index, 'key')"
              class="mr-1"
              name="key"
            ></b-form-input>
            <b-form-input
              placeholder="Value"
              :value="query.value"
              @input="handleInputChange($event, index, 'value')"
              name="value"
            ></b-form-input>
          </div>
          <b-button variant="primary" @click="handleAdd" class="actionBtn" block>Add</b-button>
          <b-button
            v-if="queries.length > 0"
            variant="danger"
            @click="handleRemove"
            class="actionBtn"
            block
          >Remove</b-button>
        </b-col>
      </b-row>
    </div>

    <div v-if="method !== 'GET'" class="body">
      <h5>Body</h5>
      <prism-editor language="js" :line-numbers="true" class="editor" v-model="body"></prism-editor>
    </div>

    <div v-if="response != null" class="response">
      <h5>Response</h5>
      <vue-json-pretty :data="response"></vue-json-pretty>
    </div>

    <div v-if="largeResponse != null" class="response">
      <h5>Response</h5>
      <pre>{{ largeResponse }}</pre>
    </div>

    <div class="d-flex justify-content-center my-5" v-if="loading">
      <msi-spinner :size="100"></msi-spinner>
    </div>
  </div>
</template>

<script>
import 'prismjs';
import 'prismjs/themes/prism.css';
import VueJsonPretty from 'vue-json-pretty';
import PrismEditor from 'vue-prism-editor';
import 'vue-prism-editor/dist/VuePrismEditor.css';
import querystring from 'querystring';
import miniToastr from 'mini-toastr';

import MsiSpinner from '../components/MsiSpinner.vue';

miniToastr.init();

export default {
  name: 'adminportal',
  components: {
    VueJsonPretty,
    PrismEditor,
    MsiSpinner
  },
  data() {
    return {
      url: 'https://api.morgansolar.xyz/api/v1/',
      method: 'GET',
      methods: ['GET', 'POST', 'PUT', 'DELETE'],
      queries: [],
      body: '',
      response: null,
      largeResponse: null,
      loading: false,
      checked: true
    };
  },
  methods: {
    handleMethodChange() {
      // When http method changes, reset state
      this.body = '';
      this.response = null;
      this.largeResponse = null;
    },
    handleFetch() {
      this.response = null;
      this.largeResponse = null;
      const { method } = this;
      let { body } = this;

      if (method !== 'GET' && body.trim()) {
        try {
          body = JSON.parse(body);
        } catch (ex) {
          miniToastr.error('The JSON you entered is invalid.', 'Invalid JSON');
          return;
        }
      } else {
        body = '';
      }

      this.loading = true;

      this.$fetch(this.url, { method, body })
        .then((res) => {
          if (res.length > 120000) {
            try {
              const resObj = JSON.parse(res);
              this.largeResponse = JSON.stringify(resObj, null, 2);
            } catch (err) {
              this.largeResponse = res;
            }
          } else {
            try {
              this.response = JSON.parse(res);
            } catch (err) {
              this.largeResponse = res;
            }
          }

          this.loading = false;
        })
        .catch((err) => {
          miniToastr.error(err.message, 'Request Error');
          console.error(err);
          this.loading = false;
        });
    },
    handleAdd() {
      if (this.queries.length === 0) {
        this.url = `${this.url}?`;
      }

      this.queries.push({ key: `key${this.queries.length}`, value: '' });
      this.changeUrl();
    },
    handleRemove() {
      this.queries.pop();
      this.changeUrl();

      if (this.queries.length === 0) {
        this.url = this.url.slice(0, this.url.length - 1);
      }
    },
    handleUrlChange() {
      const queryIndex = this.url.indexOf('?');
      if (queryIndex === -1) {
        this.queries = [];
        return;
      }

      const queryParams = this.url.slice(queryIndex + 1);
      const arrayQueryParams = [];
      const queryObj = querystring.parse(queryParams);
      for (const [key, value] of Object.entries(queryObj)) {
        if (Array.isArray(value)) {
          for (const subval of value) {
            arrayQueryParams.push({ key, value: subval });
          }
        } else {
          arrayQueryParams.push({ key, value });
        }
      }

      this.queries = arrayQueryParams;
    },
    handleInputChange(input, index, type) {
      if (type === 'key') {
        if (input === '') return;
        this.queries.splice(index, 1, { key: input, value: this.queries[index].value });
      } else {
        this.queries.splice(index, 1, { key: this.queries[index].key, value: input });
      }

      this.changeUrl();
    },
    changeUrl() {
      const queryIndex = this.url.indexOf('?');
      const newUrl = this.url.slice(0, queryIndex + 1);

      const queryParams = this.queries.map((query) => {
        if (query.key) {
          return `${encodeURIComponent(query.key)}=${encodeURIComponent(query.value)}`;
        }

        return '';
      }).join('&');

      this.url = newUrl + queryParams;
    },
    handleBaseUrlChange(checked) {
      const slashIndex = this.url.indexOf('/', 8);

      if (slashIndex < 0) {
        if (checked) this.url = 'https://api.morgansolar.xyz';
        else this.url = 'http://localhost:3000';
      } else {
        const restOfUrl = this.url.slice(slashIndex);
        if (checked) this.url = `https://api.morgansolar.xyz${restOfUrl}`;
        else this.url = `http://localhost:3000${restOfUrl}`;
      }
    }
  }
};
</script>

<style scoped>
.body {
  margin: 12px 0;
}
.method {
  margin-right: 4px;
}
.editor {
  height: 200px;
}
.response {
  margin: 12px 0;
}
.query {
  display: flex;
}
.actionBtn {
  margin-top: 8px;
}
</style>
